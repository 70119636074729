import React, { Fragment, useState } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import RenderForm from 'gatsby-plugin-cloudofertas'

/* COMPONENTS */
import Pagina from '../components/Pagina'

export default function Contato () {
  const data = useStaticQuery(
    graphql`
      query {
        pagina: contentfulPagina(path: { eq: "/contato/" }) {
          path
          metaTitle
          metaDescription
          tags
        }
        cloudOfertasForm(name: {eq: "CONTATO"}) {
          id
          name
          formTypeField {
            name
            type
            required
            style
            length
            label
            formLov {
              formLovData {
                id
                value
                createdDate
              }
            }
          }
        }
      }
    `
  )
  const [submitSuccess, setSubmitSuccess] = useState(false)

  return (
    <Fragment>
      <Pagina pagina={data.pagina} />
      <div>
        <section className='theme--quaternary'>
          <div id='img-top' className='w-100'></div>
          <div className='pb-3'>
            <div className='container pt-5 '>
              <div className='row'>
                <div className='col-12'>
                  <h2 className='theme-quinary'>
                    TIRE SUAS DÚVIDAS OU NOS ENVIE SUAS SUGESTÕES.
                  </h2>
                  <h6 className="theme-septenary">
                    <p className='m-0'>Nosso horário de atendimento por email é de segunda a sexta das 09h as 20h.</p>
                  </h6>
                </div>
              </div>
            </div>
            <div>
              { data.cloudOfertasForm.formTypeField && submitSuccess === false &&
                <RenderForm
                  setSuccessSubmit={setSubmitSuccess}
                  formData={data.cloudOfertasForm}
                  placeholder={true}
                  containerStyle='container'
                  rowStyle='row'
                  inputStyle='custom-form d-flex flex-column mt-3'
                  btnContainerStyle='custom-form col-12'
                  btnStyle='btn btn-block theme--primary btn-outline-#1A3797 theme-border-2 text-white my-3'
                  btnName='ENVIAR'
                />
              }
              {submitSuccess &&
              <div className='container mt-4'>
                <div className='row'>
                  <div className='col'>
                    <div id="alertSucesso" className="alert alert-success" role="alert">
                      Mensagem enviada com sucesso! Em breve entraremos em contato.
                    </div>
                  </div>
                </div>
              </div>
              }
            </div>
            <div className='container'>
              <div className='row'>
                <label className="col theme-septenary mt-3">
                  <span>(*) Campos obrigatórios!</span>
                </label>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  )
}
